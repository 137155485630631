<template lang="pug">
.z-dropdown-menu(:class="rootClass" ref="refRoot")
  .z-dropdown-menu__trigger(@click="triggerClick")
    slot(name="trigger")
  .z-dropdown-menu__container
    .z-dropdown-menu__body(v-show="isOpen")
      slot(name="body")
</template>

<script>
import { ref, computed, onMounted } from 'vue'

export default {
  name: 'z-dropdown-menu',
  props: {
    direction: { type: String, default: 'left'}
  },
  setup (props) {
    // console.log(props)
    const isOpen = ref(false)
    const refRoot = ref(null)

    const rootClass = computed(()=>{
      return ['z-dropdown-menu--direction-'+props.direction]
    })

    function triggerClick () {
      if (isOpen.value){
        closeMenu()
      } else {
        openMenu()
      }
    }

    function closeOnclickOutside (event) {
      if (isOpen.value) {
        // console.log(event.target)
        if (refRoot.value && !refRoot.value.contains(event.target)) {
          closeMenu()
        }
      }
    }

    function registerOnClickOutside () {
      window.addEventListener('click', closeOnclickOutside)
    }

    function destroyOnClickOutside () {
      window.removeEventListener('click', closeOnclickOutside)
    }

    function openMenu () {
      isOpen.value = true
      registerOnClickOutside()
    }

    function closeMenu () {
      isOpen.value = false
      destroyOnClickOutside()
    }

    onMounted(() => {
      refRoot.value.querySelectorAll('[dropdown-closer]').forEach(element => {
        element.addEventListener('click', closeMenu)
      })
    })

    return {
      isOpen,
      triggerClick,
      refRoot,
      rootClass
    }
  }
}
</script>

<style scoped>

.z-dropdown-menu{
  position: relative;
  display: inline-block;
}

.z-dropdown-menu__trigger {
    position: relative
}

.z-dropdown-menu__container {
    position: absolute;
    top: 100%;
    bottom: auto;
    min-width: max-content;
    max-width: 100%;
    overflow: visible;
    z-index: 999;
    box-shadow: var(--droopdown-box-shadow);
}

.z-dropdown-menu--direction-left .z-dropdown-menu__container {
  left: 0
}

.z-dropdown-menu--direction-center .z-dropdown-menu__container {
  left: 50%;
  transform: translateX(-50%) translateY(0)
}

.z-dropdown-menu--direction-right .z-dropdown-menu__container {
  right: 0;
}
</style>
