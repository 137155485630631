import { createRouter, createWebHistory } from 'vue-router'
import Panels from '../views/Panels/Panels.vue'
// import Store from '../store'

import {useAuth} from '../store/auth'
const { checkAuth, authStatus } = useAuth()

const routes = [
    {
        path: '/',
        name: 'Panels',
        component: Panels,
        meta: { auth: true },
    },
    {
        path: '/login',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login-main.vue'),
        children: [
            {
                path: '',
                redirect: '/login/signin'
            },
            {
                path: 'signin',
                component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login-signin.vue')
            },
            {
                path: 'signup',
                component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login-signup.vue')
            },
            {
                path: 'restore',
                component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login-restore.vue')
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        checkAuth()
            .then(() => {
                // debugger
                if (!authStatus.value) {
                    next('/login/signin')
                } else {
                    next()
                }
            })
    } else {
        next()
    }
})

// router.beforeEach((to, from, next) => {
//     if (to.meta.auth) {
//         Store.dispatch('AUTH')
//             .then(() => {
//                 if (!Store.getters.getAuthStatus) {
//                     next('/login/signin')
//                 } else {
//                     next()
//                 }
//             })
//     } else {
//         next()
//     }
// })

export default router
