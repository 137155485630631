import router from '../router'
import { ref, readonly } from 'vue'
import axios from '../plugins/axios'
import { notifyErrors, notifySuccess } from '../plugins/notify.js'
import io from '../plugins/socketio'

const blankUserState = {
    auth: false,
    id: null,
    name: '',
    displayed_name: '',
    name_color: '',
    text_color: '',
    admin: false,
    authLoading: false
}

const authStatus = ref(false )
const userData = ref(blankUserState)
const authLoading = ref(false )
const version = ref(0)

const setUserData = (data) =>{
    userData.value = data
    authStatus.value = !!userData.value.id
}

const checkAuth = async () =>{
    if (!authStatus.value) {
        let user_data = ''
        try {
            user_data = await axios.post('/auth/')
        } catch (error) {
            console.log(error)
        }
        if (user_data.data) {
            setUserData(user_data.data)
        } else {
            setUserData(blankUserState)
        }
    }
    return new Promise((resolve) => { resolve() })
}

const setAuthLoading = (isLoading) => {
    authLoading.value = isLoading
}

const tryLogin = (payload) => {
    setAuthLoading(true)
    return axios.post(payload.resource, payload)
        .then(response => {
            setUserData(response.data)
            setAuthLoading(false)

        })
        .catch(error => {
            setAuthLoading(false)
            notifyErrors(error, 'main', 'Ошибка авторизации')
        })
}

const tryRestore = (payload) => {
    setAuthLoading(true)

    return axios.post('/reset_password/', payload)
        .then(response => {
            setAuthLoading(false)
            notifySuccess(response.data.messages,'main')
            notifyErrors(response.data.errors,'main');
        })
        .catch(error => {
            setAuthLoading(false)
            notifyErrors(error, 'main', 'Ошибка восстановления пароля')
        })
}

const tryLogout = () => {
    axios.post('/logout/')
        .then(() => {
            setUserData(blankUserState)
            router.push('/login').catch(() => {
            })
        })
        .catch(error => {
            console.log(error)
        })
}

// io.on('update_user', user_data=>{
//     if (user_data.id === userData.value.id){
//         setUserData(user_data)
//         io.emit('update_user_session')
//     }
// })


io.on('version', data=>{
    // console.log(data)
    version.value = data
    // console.log('version',version.value)
})


export const useAuth = () => {
  return {
      authStatus: readonly(authStatus),
      userData: readonly(userData),
      authLoading: readonly(authLoading),
      version: readonly(version),
      checkAuth,
      setAuthLoading,
      tryLogin,
      tryRestore,
      tryLogout
  };
}
