import axios from 'axios'

if (document.domain==='localhost') {
  axios.defaults.baseURL = 'http://localhost:5000/api/';
}else{
  axios.defaults.baseURL = location.protocol+'//'+document.domain+'/api/';
}
axios.defaults.withCredentials = true;

export default axios
