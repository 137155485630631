// import Vue from 'vue'
// import VueMdijs from 'vue-mdijs'
// import mdiVue from 'mdi-vue/v3'

import {
    mdiClose,
    mdiMenu,
    mdiStar,
    mdiStarOutline,
    mdiSend,
    mdiCheck,
    mdiLoading,
    mdiTrashCan,
    mdiCancel,
    mdiRefresh,
    mdiPlus,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiFormatColorText,
    mdiColorHelper,
    mdiSquare,
    mdiSquareMedium,
    mdiCommentProcessing,
    mdiCommentProcessingOutline,
    mdiChevronDoubleLeft,
    mdiChevronDoubleRight,
    mdiChevronLeft,
    mdiChevronRight,
    mdiMagnify,
    mdiMenuUp,
    mdiMenuDown,
    mdiFormatBold,
    mdiFormatItalic,
    mdiFormatUnderline,
    mdiFormatStrikethrough,
    mdiFormatListBulleted,
    mdiMenuRight,
    // mdiPencil,
    mdiDotsVertical,
    mdiContentSaveOutline,
    mdiLightbulb
} from '@mdi/js'

const icons = {
    mdiClose,
    mdiMenu,
    mdiStar,
    mdiStarOutline,
    mdiSend,
    mdiCheck,
    mdiLoading,
    mdiTrashCan,
    mdiCancel,
    mdiRefresh,
    mdiPlus,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiFormatColorText,
    mdiColorHelper,
    mdiSquare,
    mdiSquareMedium,
    mdiCommentProcessing,
    mdiCommentProcessingOutline,
    mdiChevronDoubleLeft,
    mdiChevronDoubleRight,
    mdiChevronLeft,
    mdiChevronRight,
    mdiMagnify,
    mdiMenuUp,
    mdiMenuDown,
    mdiFormatBold,
    mdiFormatItalic,
    mdiFormatUnderline,
    mdiFormatStrikethrough,
    mdiFormatListBulleted,
    mdiMenuRight,
    // mdiPencil,
    mdiDotsVertical,
    mdiContentSaveOutline,
    mdiLightbulb
}

export default icons

