import { useToast } from "vue-toastification";
const toast = useToast()

const notifyErrors = (error, group, defaultErrorText) =>{
    let errors = [];
    if (error.response && 'data' in error.response && Array.isArray(error.response.data)){
        errors = error.response.data;
    }else if (Array.isArray(error)){
        errors = error;
    }else if (error.message){
        errors.push('Ошибка '+ error.message)
    }else{
        errors.push(defaultErrorText)
    }
    errors.forEach(err =>{
        // Vue.notify({
        //     group: group,
        //     type: 'warn',
        //     text: err,
        //     duration: 10000
        // })
        toast.error(err);
    });
};


const notifySuccess = (messages, group) =>{
    messages.forEach(msg =>{
        // Vue.notify({
        //     group: group,
        //     type: 'success',
        //     text: err,
        //     duration: 5000
        // })
        toast.success(msg);
    });
};

export {notifyErrors, notifySuccess}
