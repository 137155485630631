import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'

import './plugins/vh-fix'

import '@/assets/vars.css'
import '@/assets/colors.css'
import '@/assets/scrollbars.css'
import '@/assets/main.css'

import mdiVue from 'mdi-vue/v3'
import icons from './plugins/md-icons.js'
import axios from './plugins/axios.js'

// debugger
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VWave from 'v-wave'
import ZBtn from '/src/components/z-btn.vue'
import ZInput from '/src/components/z-input.vue'

import './plugins/socketio.js'
// import './store/users'

import { createHead } from '@vueuse/head'
const head = createHead()

const app = createApp(App)
    // .use(store)
    .use(router)
    .use(mdiVue, { icons: icons })
    .use(VWave)
    .use(Toast)
    .use(head)
    .component('z-btn', ZBtn)
    .component('z-input', ZInput)

app.mount('#app')
