import io from 'socket.io-client';

let port = '';
if (document.domain==='localhost') {
  port = ':5000'
}
const socketIO = io(document.domain+port, {
    transports: ['websocket'],
    autoConnect: true,
    multiplex: false,
    forceNew: false
});

// console.log(socketIO)
socketIO.connect()

export default socketIO
