const skins = [
    {key: 'new',           theme_color:'#42b983',   title: 'Желто-зеленый'},
    {key: 'light',         theme_color:'#FFFFFF',   title: 'Светлый'},
    {key: 'dragon',        theme_color:'#D9C09D',   title: 'Dragon'},
    {key: 'oceanZero',     theme_color:'#CFE7F0',   title: 'Ocean zero'},
    {key: 'dracula',       theme_color:'#6272a4',   title: 'Dracula'},
    {key: 'borland',       theme_color:'#b5b8b5',   title: 'Borland'},
    {key: 'solarDark',     theme_color:'#073642',   title: 'Солнечный темный'},
];

const fonts = [
    {key: 'roboto_mono', title: 'Roboto Mono (По умолчанию)', css: "@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:100,300,400,700'); * {--font-family: 'Roboto Mono', monospace;}"},
    {key: 'fira_code',   title: 'Fira code', css: "@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap'); * {--font-family: 'Fira Code', monospace;"},
    {key: 'roboto',      title: 'Roboto', css: "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700'); * {--font-family: 'Roboto', sans-serif;}"},
    {key: 'verdana',     title: 'Verdana', css:"* {--font-family: Verdana}"},
];

const defaultSkin = 'solarDark';
const defaultFont = 'roboto_mono';
const defaultFontSize = 16;

function getSetting(settingKey, defaultSetting, settingsList=null){
   let currentSetting = localStorage.getItem(settingKey)
    if (!currentSetting) {
        currentSetting = defaultSetting
    }
    if (settingsList){
        return settingsList.find(item => item.key === currentSetting)
    } else {
        return currentSetting
    }
}

export {skins, fonts, defaultSkin, defaultFont, defaultFontSize, getSetting}
