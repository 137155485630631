<template lang="pug">
button(:class="buttonClass" :disabled="disable")
    z-loading(v-if="showLoader" class="z-btn__loader" :timeout="0")
    div(v-if="!disable" class="z-btn__wave" v-wave )
    div(:class="buttonDivClass" )
        slot
            mdicon(v-if="icon" :name="icon")
</template>

<!--<script>-->
<!--export default { name: 'z-btn' }-->
<!--</script>-->

<script setup>
import { ref, computed, defineProps, watch } from 'vue'
import ZLoading from './z-loading'

const props = defineProps({
    loading: { default: false },
    icon: { default: '' },
    transparent: { default: false },
    disable: { default: false },
    dark: { default: false },
    loading_timeout: {
        default: 500,
        type: Number
    }
})

const loadingStarted = ref(props.loading)

watch(
  () => props.loading,
  (newVal) => {
    loadingStarted.value = false
    if (newVal) {
        setTimeout(() => {
            loadingStarted.value = true
        }, props.loading_timeout)
    }
  }
)

const buttonClass = computed(() => {
    return {
        'z-btn': true,
        'z-btn-transparent': props.transparent,
        'z-btn-disable': props.disable,
        'z-btn-dark': props.dark
    }
})

const showLoader = computed(()=> (loadingStarted.value && props.loading))

// const wave = computed(()=> (props.disable) ? {color: 'transparent'} : {
//     color: 'red',
//     initialOpacity: 0.5,
//     easing: 'ease-in',
// })

const buttonDivClass = computed(() => {
    return {
        'z-btn__content': true,
        'z-btn__content_loading': showLoader.value
    }
})

function button(event){
    if (props.disable){
        event.preventDefault()
        event.stopPropagation()
    }
}

</script>

<style scoped>
.z-btn {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    /*box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);*/
    color: var(--alter-text-color);
    border-radius: 0.1rem;
    /*text-transform: uppercase;*/

    padding: 0.5rem 1rem;
    background-position: center;
    transition: background 0.8s;

    /*align-items: center;*/
    /*display: inline-flex;*/
    flex: 0 0 auto;
    /*justify-content: center;*/
    outline: 0;
    position: relative;
    text-decoration: none;
    transition-duration: .28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
}

.z-btn:hover {
    filter: brightness(1.1);
}

.z-btn-disable,
.z-btn-disable:hover {
    filter: grayscale(200%);
    background: none !important;
    cursor: default;
    border: 1px solid var(--regular-text-color);
    color: 1px solid var(--regular-text-color);
    /*user-input: none;*/
}


.z-btn-transparent {
    background: transparent;
    color: var(--regular-text-color);
    border-radius: 0;
    border: none;
}

.z-btn-transparent:hover {
    background: var(--transparent-hover-background);
    filter: var(--transparent-hover-filter);
    border: none;
}

.z-btn__wave{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* .z-btn-disable .z-btn-transparent:hover{*/
/*    background: none !important;*/
/*}*/
/*.ripple {*/
/*    position: relative;*/
/*    overflow: hidden;*/
/*}*/

/*.ripple:after {*/
/*    content: "";*/
/*    background: rgba(255, 255, 255, 0.3);*/
/*    display: block;*/
/*    position: absolute;*/
/*    border-radius: 50%;*/
/*    padding-top: 240%;*/
/*    padding-left: 240%;*/
/*    margin-top: -120%;*/
/*    margin-left: -120%;*/
/*    opacity: 0;*/
/*    transition: all 0.7s;*/
/*}*/

/*.ripple:active:after {*/
/*    padding-top: 0;*/
/*    padding-left: 0;*/
/*    margin-top: 0;*/
/*    margin-left: 0;*/
/*    opacity: 1;*/
/*    transition: 0s;*/
/*}*/

/*.z-btn-disable~.ripple-container{*/
/*    display: none;*/
/*    background: red;*/
/*}*/


.z-btn__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.z-btn__loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.z-btn__content_loading {
    opacity: 0;
}

.z-btn-dark {
    color: var(--alter-text-color);
}

.z-btn-dark:hover {
    filter: brightness(0.9);
}
</style>
