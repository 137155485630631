<template lang="pug">
div(class="panel")
    div(class="panel__header")
        div(class="panel__header_left")
            slot(name="panel-menu")
        div(class="panel__header_right")
            slot(name="panel-right")
    div(class="panel__content")
        component(v-bind:is="activePanel.component" :panel="panel" @append_to_route="appendToRoute" @remove_from_route="removeFromRoute")
</template>

<script setup>

import { defineProps, defineEmits, computed } from 'vue'
import Panels from '../../panels/~panels'
import Blank from '../../panels/Blank'

const props = defineProps({panel:{}})
const emit = defineEmits(['append_to_route', 'remove_from_route'])

const activePanel = computed(() => {
    let curPanel = Panels[props.panel.type]
    if (curPanel) {
        return curPanel
    } else {
        return {
            title: '<Пусто>',
            component: Blank
        }
    }
})

function appendToRoute (param) {
    emit('append_to_route', param)
}

function removeFromRoute (param) {
    emit('remove_from_route', param)
}
</script>

<style scoped>
    .panel{
        height: 100%;
        width: 100%;
        background: var(--panel_background);
        color: var(--panel_color);
        min-height: var(--block-height-big);
    }

    .panel__header{
        height: var(--panel__header_height);
        background: var(--panel__header_background);
        color: var(--panel__header_color);
        display: flex;
        justify-content: space-between;
        /*background-image: url('../../assets/img/2.jpg');*/
    }

    .panel__content{
        height: calc(100% - var(--panel__header_height));
        overflow: hidden;
    }

    .panel__header_left,
    .panel__header_right{
        display: flex;
    }

    .panel__header_right>.z-btn{
        overflow: hidden;
    }
</style>
