<template lang="pug">
ul
  li(v-for="(item, itemKey) in items" :key="`item_${itemKey}`" v-html="item.title" @click="itemClick(itemKey)")
</template>

<script>

export default {
  name: 'panels-menu-list',
  props: ['items'],
  setup( props, { emit } ){
    function itemClick(itemKey){
      emit('select', itemKey)
    }
    return {
      itemClick
    }
  }
  // methods: {
  //   itemClick (itemKey) {
  //     this.$emit('select', itemKey)
  //   }
  // }
}
</script>

<style scoped>

</style>
