<template lang="pug">
span(class="kong" @click="doReconnect")
    img(v-show="bigKong" :src="require('../../assets/img/kong_challenge.gif')" class="kong__img")
    span(class="kong__counter") {{kongCounter}}
</template>

<script setup>
// import io from '../../plugins/socketio'
import io from '/src/plugins/socketio'
import { ref, computed } from 'vue'

let kongTime = new Date()
const kongCounter = ref(0)

// io.on('ping', () => {
//     kongTime = new Date()
// })

// io.on('pong', () => {
//     console.log('pong!')
// })

const bigKong = computed(()=>{
    return  kongCounter.value >= 1000
})

function calcKong() {
    kongCounter.value = ~~((new Date() - kongTime) / 10)
    io.volatile.emit("ping", () => {
      kongTime = new Date()
    });
}

setInterval(calcKong, 1000)

function doReconnect () {
    kongTime = new Date()
    io.disconnect()
    io.open()
}

function safeReconnect () {
    let theTime = Math.abs(new Date() - kongTime)
    if (theTime > 3500) {
        doReconnect()
    }
}

window.onblur = function () {
    safeReconnect()
    calcKong()
}
window.onfocus = function () {
    safeReconnect()
    calcKong()
}

// setInterval(() => {
//   const start = Date.now();
//
//   io.volatile.emit("ping", () => {
//     kongCounter.value = Date.now() - start;
//   });
// }, 2000);

</script>

<style>
.kong {
    right: 2.2rem;
    height: 2rem;
    line-height: 2rem;
    /*color: white;*/
    display: flex;
    float: right;
    cursor: pointer;
    user-select: none;
    margin-right: 0.35rem;
}

.kong__counter {
    display: inline-block;
    min-width: 2rem;
    text-align: right;
}

.kong__img {
    display: inline-block;
    max-height: 100%;
}

</style>
