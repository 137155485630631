<template lang="pug">
div(v-show="show" :class="rootClasses")
    mdicon(name="loading" :spin="true" :size="size")
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'

const props = defineProps({
    size: { default: 24 },
    overlay: {type:Boolean, default: false },
    timeout: { default: 500 }
})

const show = ref(false)
const rootClasses = computed(()=> (props.overlay) ? ['overlay'] : [])

setTimeout(()=>{show.value=true}, props.timeout)

</script>

<style scoped>
    .overlay{
        background: var(--overlay-background);
        z-index: 2;
    }
</style>
