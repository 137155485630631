<template lang="pug">
//z-new-year
splitpanes(class="default-theme app-panes" :style="`height:${windowHeight}px`" :dbl-click-splitter="false" @resized="panelsResized" @ready="panelsReady")
    template(v-for="(column, columnIndex) in panels" :key="`panel_${columnIndex}`")
        pane(:data-size="getPanelColumnSize(columnIndex)"
            :size="getPanelColumnSize(columnIndex)"
            min-size="10"
            :class="{'app-panes__first-pane': columnIndex===0, 'app-panes__last-pane': columnIndex===panels.length-1}"
            )
            splitpanes(:key="`column_${columnIndex}`" horizontal :dbl-click-splitter=" false" min-size="10")
                pane(v-for="(row, rowIndex) in column" :key="`row_${columnIndex}_${rowIndex}`")
                    panel(:panel="row" @append_to_route="appendToRoute(row, column, $event)" @remove_from_route="removeFromRoute(row, column, $event)")
                        template(v-slot:panel-menu)
                            z-dropdown-menu
                                template(v-slot:trigger)
                                    z-btn(class="panel__header__menu-button" :transparent="true") {{ panelsList[row.type].title }}
                                template(v-slot:body)
                                    ul(class="panel__header__menu_ul")
                                        template(v-for="(panelElem, panelName) in panelsListMenu" :key="`panelElem${panelName}`")
                                            li(@click="selectPanel(row, panelName)" dropdown-closer class="panel__header__menu_li z-list-item")
                                                div {{ panelElem.title }}
                                        li(class="z-divider")
                                        li(class="panel__header__menu_li z-list-item" @click="clickRight")
                                            div(class="panel__header__menu_li-text") Открыть справа
                                            div(class="panel__header__menu_li-icon")
                                                mdicon(name="menu-right")
                                                panels-menu-list( v-show="selectedRight"
                                                    :items="panelsListMenu"
                                                    class="panel__header__menu__submenu"
                                                    @select="addColumn")
                                        li(class="panel__header__menu_li z-list-item" @click="clickDown")
                                            div(class="panel__header__menu_li-text") Открыть снизу
                                            div(class="panel__header__menu_li-icon")
                                                mdicon(name="menu-right")
                                                panels-menu-list(v-show="selectedDown"
                                                    :items="panelsListMenu"
                                                    class="panel__header__menu__submenu"
                                                    @select="addRow($event, column)")
                                        li(class="panel__header__menu_li-divider")
                                        li(class="panel__header__menu_li-buttons")
                                            z-btn(icon="chevron-left" @click="columnShift(1)" :transparent="true")
                                            z-btn(icon="chevron-right" @click="columnShift(-1)" :transparent="true")
                        template(v-slot:panel-right)
                            panels-kong(v-if="rowIndex===0 && columnIndex===0")
                            z-dropdown-menu(v-if="rowIndex===0 && columnIndex===panels.length-1" :direction="'right'" class="panel__header__menu")
                                template(v-slot:trigger)
                                    z-btn(class="panel__header__close-button" icon="menu" :transparent="true")
                                template(v-slot:body)
                                    ul(class="panel__header__menu_ul" dropdown-closer)
                                        li(class="z-list-item panel__header__menu_li panel__header__menu_li-right" @click="selectPanel(row, 'profile')")
                                            div(class="panel__header__menu_li-text") Настройки
                                        li(class="z-list-item panel__header__menu_li" @click="clickLogout")
                                            div(class="panel__header__menu_li-text") Выход
                            z-btn(v-if="panels.length>1 || panels[0].length>1"
                                class="panel__header__close-button"
                                icon="close"
                                @click="closePanel(columnIndex, rowIndex)"
                                :transparent="true")
</template>

<script>
// import PanelsMenuList from './PanelsMenuList'
// import ZDropdownMenu from '../../components/z-dropdown-menu'
// import ZNewYear from '../../components/z-new-year'
// import Panel from './PanelsPanel'
import Panels from '../../panels/~panels'
// import isMobile from 'is-mobile'
// import isMobile from 'is-mobile'
import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent);
// console.log( md.mobile() );          // 'Sony'
// console.log( md.phone() );           // 'Sony'
// console.log( md.tablet() );          // null
// console.log( md.userAgent() );       // 'Safari'
// console.log( md.os() );              // 'AndroidOS'
// console.log( md.is('iPhone') );      // false
// console.log( md.is('bot') );         // false
// console.log( md.version('Webkit') );         // 534.3
// console.log( md.versionStr('Build') );       // '4.1.A.0.562'
// console.log( md.match('playstation|xbox') ); // false

export default {
    beforeRouteEnter (to, from, next) {
        if ('panels' in to.query) {
            next()
        } else {
            let panelsQuery = ''
            if ('panels_query' in localStorage) {
                panelsQuery = localStorage.getItem('panels_query')
            } else {
                let panelsArray = []
                let panelsKeys = Object.keys(Panels)
                panelsArray.push([{ type: panelsKeys[0] }])
                // if (!isMobile()) {
                if (!md.phone()) {
                    panelsArray.push([{ type: panelsKeys[1] }])
                }
                panelsQuery = JSON.stringify(panelsArray)
            }
            next('/?panels=' + panelsQuery)
        }
    }
}
</script>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { emitter } from '../../plugins/emitter'
// import { useStore } from 'vuex'
import { useAuth } from '../../store/auth'
const { tryLogout } = useAuth()

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import Panel from './Panels-panel'
import PanelsKong from './Panels-kong'
import ZDropdownMenu from '../../components/z-dropdown-menu'
import PanelsMenuList from './Panels-menuList'

const router = useRouter()
const route = useRoute()
// const store = useStore()
const windowHeight = ref(window.innerHeight)

function getQueryPanels () {
    const panelsStr = String(route.query.panels)
    if (panelsStr) {
        return JSON.parse(panelsStr)
    } else {
        return []
    }
}

function getPanelsSize () {
    if ('panels_size' in localStorage) {
        let panelsSize = JSON.parse(localStorage.getItem('panels_size'))
        if (panelsSize) {
            return panelsSize
        }
    } else {
        return []
    }
}

const panels = ref(getQueryPanels())
const panelsSize = getPanelsSize()

function getPanelColumnSize (columnIndex) {
    if (panelsSize.length > columnIndex) {
        return panelsSize[columnIndex].size
    } else {
        return undefined
    }
}

function panelsReady () {
    getCurrentInstance().update()
}

function panelsResized (panels_size) {
    localStorage.setItem('panels_size', JSON.stringify(panels_size))
}

window.addEventListener('resize', () => {
    windowHeight.value = window.innerHeight
})

//ROUTE
function updateRouteQuery () {
    panels.value.forEach(column => {
        column.forEach(row => {
            let panel = Panels[row.type]
            for (let attrKey in row) {
                // debugger
                if (attrKey !== 'type' && !panel.params.includes(attrKey)) {
                    delete row[attrKey]
                }
            }
        })
    })

    let panelsQuery = JSON.stringify(panels.value)
    if (panelsQuery !== route.query.panels) {
        router.replace({
            path: route.path,
            query: { panels: panelsQuery }
        })
    }
    localStorage.setItem('panels_query', panelsQuery)
}

function appendToRoute (row, column, param) {
    // debugger
    Object.assign(row, param)
    updateRouteQuery()
}

function removeFromRoute (row, column, param) {
    delete row[param]
    updateRouteQuery()
}

// PANEL
const panelsList = ref(Panels)
const selectedRight = ref(false)
const selectedDown = ref(false)

const panelsListMenu = computed(() => {
    // return panelsList.value.filter
    let result = {}
    for (let key in panelsList.value) {
        if (panelsList.value[key].inMenu) {
            result[key] = panelsList.value[key]
        }
    }
    return result
})

function clickRight () {
    selectedRight.value = !selectedRight.value
    selectedDown.value = false
}

function clickDown () {
    selectedDown.value = !selectedDown.value
    selectedRight.value = false
}

function selectPanel (panel, panelName) {
    panel.type = panelName
    updateRouteQuery()
}

function columnShift (direction) {
    panels.value = panels.value.splice(direction).concat(panels.value)
    updateRouteQuery()
}

function addRow (panelName, column) {
    column.push({ type: panelName })
    updateRouteQuery()
}

function addColumn (panelName, data = {}) {
    data['type'] = panelName
    // console.log(data)
    panels.value.push([data])
    updateRouteQuery()
}

function closePanel (columnIndex, rowIndex) {
    let panels_list = panels.value.slice()
    panels_list[columnIndex].splice(rowIndex, 1)
    if (panels_list[columnIndex].length === 0) {
        panels_list.splice(columnIndex, 1)
    }
    panels.value = panels_list
    updateRouteQuery()
}

function clickLogout () {
    tryLogout()
}

emitter.on('openForum', data=>{
    // console.log(data)
    let isForum = false
    for (let column in panels.value) {
        for (let row in panels.value[column]) {
            let curPanel = panels.value[column][row]
            if (curPanel.type === 'forum') {
                isForum = true
                curPanel.forum_id = data.forumId
                updateRouteQuery()
                break
            }
        }
        if (isForum){
            break
        }
    }
    if (!isForum) {
        addColumn('forum', { 'forum_id': data.forumId })
    }
})

//   on: {
//     openForum (data) {
//       // console.log(this.panels)
//       // this.openForumById(data.forumId);
//       // this.$emit('append_to_route', {forum_id:data.forumId})
//       let isForum = false
//       for (let column in this.panels) {
//         for (let row in this.panels[column]) {
//           if (this.panels[column][row].type === 'forum') {
//             isForum = true
//           }
//         }
//       }
//       if (!isForum) {
//         this.addColumn('forum', { 'forum_id': data.forumId })
//       }
//     },
//   },

</script>

<style lang="css" scoped>
.app-panes {
    width: 100vw;
    overflow: visible;
    max-height: 100vh;
}

.panel__header__menu-button,
.panel__header__close-button {
    height: var(--panel__header_height);
    color: var(--panel__header_color) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.panel__header__menu-button {
    padding: 0 calc(var(--indent-size) + 1px) !important;
    padding-bottom: 0.1rem !important;
}


.panel__header__close-button {
    min-width: 2.5rem;
    max-width: 2.5rem;
}

.panel__header__menu-button:hover,
.panel__header__close-button:hover {
    background: var(--panel__header__menu-button_background-hover);
    color: var(--panel__header__menu-button_color-hover);
}

.panel__header__menu_ul {
    margin: 0;
    padding: 0;
    background: var(--panel__header__menu_background);
    color: var(--panel__header__menu_color);
    position: relative;
}

.panel__header__menu_li,
.panel__header__menu__submenu ::v-deep(li) {
    height: var(--panel__header__menu_height);
    line-height: var(--panel__header__menu_height);
    cursor: pointer;
    padding-left: var(--indent-size-big);
    display: flex;
    position: relative;
}

.panel__header__menu_li-right {
    padding-right: var(--indent-size-big);
}

.panel__header__menu__submenu {
    box-shadow: var(--droopdown-box-shadow);
}

.panel__header__menu__submenu ::v-deep(li) {
    white-space: pre;
    padding-right: var(--indent-size-big);
}

.panel__header__menu_li-buttons {
    display: flex;
    justify-content: center;
    max-height: var(--block-height-medium);
    padding-right: 1rem;
}

.panel__header__menu_li-buttons ::v-deep(.z-btn) {
    max-width: 2.5rem;
}

.panel__header__menu__submenu {
    position: absolute;
    background: var(--panel__header__menu_background);
    color: var(--panel__header__menu_color);
    top: 0;
    transform: translateX(calc(2.5rem - 4px));
    z-index: 2;
}

.panel__header__menu_li:hover,
.panel__header__menu__submenu ::v-deep(li:hover) {
    background: var(--panel__header__menu-button_background-hover);
    color: var(--panel__header__menu-button_color-hover);
}

.panel__header__menu_li-text {
    flex: 1;
    color: inherit;
    text-decoration: none;
}

.panel__header__menu_li-icon {
    height: var(--panel__header__menu_height);
    width: 2rem;
    flex: 0;
    display: flex;
    align-items: center;
    position: relative;
}

</style>

<style lang="css">
.splitpanes--vertical > .splitpanes__splitter {
    min-width: 1px;
    max-width: 1px;
}

.splitpanes--horizontal > .splitpanes__splitter {
    min-height: 1px;
    max-height: 1px;
}

.splitpanes {
    --splitter-zone-size: 4px;
    --splitter-zone-size--halph: -2px;
}

@media (max-width: 30rem) {
    .splitpanes {
        --splitter-zone-size: 40px;
        --splitter-zone-size--halph: -20px;
    }
}

.splitpanes__pane {
    overflow: visible;
    min-width: 8rem;
    min-height: var(--block-height-medium);
}


.splitpanes.default-theme .splitpanes--horizontal .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes--horizontal .splitpanes__splitter:before {
    transform: translateX(-50%);
    height: var(--splitter-zone-size);
    width: 100%;
    margin-top: var(--splitter-zone-size--halph) !important;
    background: transparent;
    z-index: 30;
}

.default-theme.splitpanes--vertical .splitpanes__splitter:before,
.default-theme.splitpanes--vertical .splitpanes__splitter:after {
    transform: translateY(-50%);
    height: 100%;
    width: var(--splitter-zone-size);
    margin-left: var(--splitter-zone-size--halph) !important;
    background: transparent;
    z-index: 30;
}
</style>
