import { defineAsyncComponent } from 'vue'

const panels = {
    chat: {
        title: 'Чат',
        params: ['room_id'],
        inMenu: true,
        component: defineAsyncComponent(() => import(/* webpackChunkName: "chat" */ '../panels/Chat/Chat-main'))
    },
    forum: {
        title: 'Форум',
        params: ['forum_id', 'editor'],
        inMenu: true,
        component: defineAsyncComponent(() => import(/* webpackChunkName: "forum" */ '../panels/Forum/Forum-main'))
    },
    chat_archive: {
        title: 'Архив чата',
        params: ['room_id', 'page', 'search'],
        inMenu: true,
        component: defineAsyncComponent(() => import(/* webpackChunkName: "chat" */ '../panels/Chat/Chat-archive'))
    },
    forum_archive: {
        title: 'Архив форума',
        params: ['forum_id', 'page', 'search'],
        inMenu: true,
        component: defineAsyncComponent(() => import(/* webpackChunkName: "forum" */ '../panels/Forum/Forum-archive'))
    },
    notes: {
        title: 'Заметки',
        params: ['note_id'],
        inMenu: true,
        component: defineAsyncComponent(() => import(/* webpackChunkName: "notes" */ '../panels/Notes/Notes-main'))
    },
    arhibash: {
        title: 'Архибаш',
        params: ['page'],
        inMenu: true,
        component: defineAsyncComponent(() => import(/* webpackChunkName: "arhibash" */ '../panels/Arhibash/Arhibash-main'))
    },
    profile: {
        title: 'Настройки',
        params: [],
        inMenu: false,
        component: defineAsyncComponent(() => import(/* webpackChunkName: "profile" */ '../panels/Profile/Profile-main'))
    },
}

export default panels
