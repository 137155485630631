<template lang="pug">
//div {{version}} {{skin.theme_color}}
router-view
</template>

<script setup>
import { computed, ref } from 'vue'
import { useHead } from '@vueuse/head'
import { skins, fonts, defaultSkin, defaultFont, defaultFontSize, getSetting } from "./styles";

import {emitter} from './plugins/emitter'

emitter.on('changeSettings', readSettings)

const skin = ref(null)
const font = ref( null)
const fontSize = ref(null)
const version = ref('')
readSettings()

function readSettings(){
    skin.value = getSetting('skin', defaultSkin, skins)
    font.value = getSetting('font', defaultFont, fonts)
    fontSize.value = Number(getSetting('font_size', defaultFontSize))
    // console.log(skin.value, font.value)
}

useHead({
    style: computed(()=>[
        {type: 'text/css', children: `*{--font-size: ${fontSize.value}px; --font-size-small: ${fontSize.value - 3}px; --font-size-big: ${Number(fontSize.value) + 4}px;}`},
        {type: 'text/css', children: `@import url('skins/${skin.value.key}.css?${version.value}')` },
        {type: 'text/css', children: `${font.value.css}` },
    ]),
    meta: computed(() => [
        { name: 'theme-color', content: skin.value.theme_color, id: 'theme-color'}
    ])
})

window.addEventListener('resize', ()=>{
    emitter.emit('resize')
})

</script>

<style lang="css">
body {
  margin: 0;
}
</style>
