<template lang="pug">
div(:class="{'z-input': true, 'z-input-focused':isFocused, 'z-input-dense':dense}")
    div(v-html="label" class="z-input__input__label")
    input(
        ref="input"
        @input="currentValueInput"
        @focus="inputFocus"
        @blur="inputBlur"
        @keydown="onKeydown"
        class="z-input__input"
        :type="type"
        :placeholder="placeholder"
        v-model = "modelValue"
    )
    div(class="z-input-focus-border")
</template>

<script setup>
import { ref, defineProps, defineEmits, nextTick } from 'vue'

const props = defineProps({
        modelValue: {},
        label: { default: '' },
        type: {},
        placeholder: {default: '', type: String},
        dense: { default: false, type: Boolean},
        autofocus: { default: false, type: Boolean},
    })

const emit = defineEmits([
        'update:modelValue',
        'change:modelValue',
        'focus',
        'blur'
    ])

const input = ref(null)
const isFocused = ref(false)

function onChange (event) {
    emit('change:modelValue', event)
}

function currentValueInput ($event) {
    emit('update:modelValue', $event.target.value)
}

function inputFocus () {
    isFocused.value = true
    emit('focus')
}

function inputBlur () {
    isFocused.value = false
    emit('blur')
}

function focus () {
    input.value.focus()
}

if (props.autofocus){
    nextTick(() => focus())
}
</script>

<style>
.z-input {
    margin-bottom: var(--indent-size);
    width: 100%;
    position: relative;
}

.z-input:hover {
    /*filter: var(--transparent-hover-filter);*/
}

.z-input__input__label {
    /*margin-bottom: calc(var(--indent-size)/4);*/
    font-size: var(--font-size-small);
}

.z-input-focused > .z-input__input__label {
    color: var(--primary-color);
}

.z-input__input {
    padding-bottom: var(--indent-size);
    padding-top: var(--indent-size);
    border-bottom: 1px solid var(--regular-text-color);
    color: var(--regular-text-color);
    width: 100%;
}

.z-input .z-input__input ~ .z-input-focus-border {
    position: absolute;
    bottom: auto;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--primary-color);
    transition: 0.4s;
    transform: translateY(-2px);
}

.z-input-focused .z-input__input ~ .z-input-focus-border {
    width: 100%;
    transition: 0.3s;
    left: 0;
}

.z-input-dense input {
    padding-top: var(--indent-size-small);
    margin-top: var(--indent-size);
    padding-bottom: var(--indent-size-small);
}

</style>
